import React, { useState } from "react";
import { WebMercatorViewport } from "react-map-gl";

// Components
import { Map } from "./map";

export const DesktopMap = ({ activeMarker, allMapLocations, locations }) => {
  const applyToArray = (func, array) => func.apply(Math, array);
  const getBoundsForPoints = points => {
    // Calculate corner values of bounds
    const pointsLong = points.map(point => point.longitude);
    const pointsLat = points.map(point => point.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];

    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 500,
    }).fitBounds(cornersLongLat, { padding: 50 }); // Can also use option: offset: [0, -100]

    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  const mapPoints = locations.map((listing, index) => {
    return {
      longitude: listing.node.data.location.longitude,
      latitude: listing.node.data.location.latitude,
    };
  });

  const bounds = getBoundsForPoints(mapPoints);
  const [viewport, setViewport] = useState({
    ...bounds,
  });

  return (
    <Map
      viewport={viewport}
      setViewport={setViewport}
      activeMarker={activeMarker}
      allMapLocations={allMapLocations}
    />
  );
};
