import React, { useEffect, useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { withPreview } from "gatsby-source-prismic";
import { createBreakpoint } from "react-use";

// SEO
import { SEO } from "../components/seo/seo";

// Context
import { ActiveListingCountry } from "../components/context/active-country";
import { PageTypeContext } from "../components/context/page-type";

// Components
import { ListingsAvailableSoon } from "../components/listing/listings-available-soon";
import { AllListingsInCountryMap } from "../components/maps/all-listings-in-country-map";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Utils
import { dynamicSort } from "../components/utils/dynamic-sort";

// Layout
import {
  Listing,
  SortContainer,
} from "../components/listing/listing-components";

// Containers
import { MasonryContainer } from "../components/containers/masonry-container";
import { ActiveListingCity } from "../components/context/active-city";

// Setup dayjs
dayjs.extend(relativeTime);

const Page = styled.div`
  padding: 0 90px;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Heading = styled.div`
  margin: 30px 0 114px 0;

  & h1 {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }

  @media (max-width: 768px) {
    margin: 30px 0 20px 0;
  }
`;

const Line = styled.hr`
  height: 1px;

  background-image: linear-gradient(
    to right,
    #0e3563 33%,
    rgba(14, 53, 99, 0) 0%
  );

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  margin: 25px 0 0 0;
  padding: 0;
  border: 0;
`;

const LookingContainer = styled.div`
  margin: 50px 0 0 0;

  @media (max-width: 768px) {
    margin: 20px 0 0 0;
  }

  & p {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;

    margin: 0;

    & a {
      text-decoration: none;
      border-bottom: 2px solid rgb(14 53 99);

      &:hover {
        border-bottom: 2px solid rgb(14 53 99);
      }
    }

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.05em;
    }
  }
`;

const useBreakpoint = createBreakpoint({ M: 601, S: 10 });

const Country = ({ data }) => {
  const [activeSort, setActiveSort] = useState("alphabetical");
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);
  const [activeCountry, setActiveCountry] = useContext(ActiveListingCountry);
  const [activeCity, setActiveCity] = useContext(ActiveListingCity);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageTypeContext(`country`);
    setActiveCity(null);
    setActiveCountry({
      name: data.prismicCountry.data.name.text,
      url: data.prismicCountry.url,
    });
  }, []);

  const content = data.allPrismicListing.edges
    .sort(dynamicSort(activeSort))
    .map((content, index) => (
      <Listing key={`homepage_listing_${content.node.id}`}>
        <Link to={content.node.url}>
          {content.node.data.thumbnail.fluid !== null && (
            <AspectRatioImageContainer image={content.node.data.thumbnail}>
              <img
                src={content.node.data.thumbnail.fluid.srcWebp}
                srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
                alt={content.node.data.thumbnail.alt}
                loading="lazy"
              />
            </AspectRatioImageContainer>
          )}
        </Link>
        <div className="listing-title">
          <p className="transcript">
            <Link to={content.node.url}>{content.node.data.title.text}</Link>
          </p>
          {(activeSort === `price` || activeSort === `-price`) && (
            <p className="crimson">
              <em>
                {content.node.data.price_text}{" "}
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: data.prismicCountry.data.currency_code,
                  maximumSignificantDigits: Math.trunc(
                    Math.abs(content.node.data.single_night_price)
                  ).toFixed().length,
                }).format(content.node.data.single_night_price)}
                /night
              </em>
            </p>
          )}
          {(activeSort === `sleeps` || activeSort === `-sleeps`) && (
            <p className="crimson">
              <em>Sleeps {content.node.data.sleeps}</em>
            </p>
          )}
        </div>
      </Listing>
    ));

  return (
    <>
      <SEO
        seoTitle={data.prismicCountry.data.name.text}
        seoText={` All places to stay in ${data.prismicCountry.data.name.text}`}
        seoImage={``}
      />
      <ListingsAvailableSoon listings={data.allPrismicListing.edges} />
      <Page>
        <Heading>
          <Flex>
            <h1 className="transcript">
              All places to stay in {data.prismicCountry.data.name.text}
            </h1>

            <SortContainer className="transcript">
              <p className="sort-title">Sort:</p>
              <button
                className={
                  activeSort === `alphabetical` ||
                  activeSort === `-alphabetical`
                    ? `active-sort`
                    : ``
                }
                onClick={() =>
                  setActiveSort(
                    activeSort === "alphabetical"
                      ? "-alphabetical"
                      : "alphabetical"
                  )
                }
              >
                A-Z
              </button>
              <button
                className={
                  activeSort === `price` || activeSort === `-price`
                    ? `active-sort`
                    : ``
                }
                onClick={() =>
                  setActiveSort(activeSort === "price" ? "-price" : "price")
                }
              >
                Price
              </button>
              <button
                className={
                  activeSort === `sleeps` || activeSort === `-sleeps`
                    ? `active-sort`
                    : ``
                }
                onClick={() =>
                  setActiveSort(activeSort === "sleeps" ? "-sleeps" : "sleeps")
                }
              >
                Sleeps
              </button>
            </SortContainer>
          </Flex>
        </Heading>

        <MasonryContainer>{content}</MasonryContainer>

        <LookingContainer>
          {data.prismicCountry.data.bottom_text !== null &&
            data.prismicCountry.data.bottom_text !== "" && (
              <div
                className="transcript"
                dangerouslySetInnerHTML={{
                  __html: data.prismicCountry.data.bottom_text.html,
                }}
              />
            )}
          <Line />
        </LookingContainer>

        {data.allPrismicListing.edges.length >= 1 && (
          <AllListingsInCountryMap
            locations={data.allPrismicListing.edges}
            breakpoint={breakpoint}
          />
        )}
      </Page>
    </>
  );
};

export default withPreview(Country);

export const query = graphql`
  query Country($uid: String!) {
    prismicCountry(uid: { eq: $uid }) {
      url
      data {
        name {
          text
        }
        bottom_text {
          html
          text
        }
        currency_code
      }
    }
    allPrismicListing(
      sort: { fields: data___title___text, order: ASC }
      filter: { data: { country: { uid: { eq: $uid } } } }
    ) {
      edges {
        node {
          id
          url
          data {
            title {
              text
            }
            sleeps
            price_text
            single_night_price
            size {
              text
            }
            start_date
            location {
              latitude
              longitude
            }
            end_date
            price
            travel_time_from_city {
              text
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              fluid {
                srcWebp
                srcSetWebp
                aspectRatio
              }
            }
            fallback_city
            city {
              document {
                ... on PrismicCity {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            country {
              document {
                ... on PrismicCountry {
                  id
                  url
                  data {
                    name {
                      text
                    }
                    currency_code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
