import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactMapGL, { Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// Components
import { Navigation } from "./navigation";
import { LinkIcon } from "../icons/link-arrow";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  margin: 35px 0 80px 0;

  @media (max-width: 768px) {
    margin: 25px 0 80px 0;
  }

  @media (max-width: 600px) {
    height: 320px;
  }

  & .mapboxgl-popup-tip {
    border: 0;
  }

  & .mapboxgl-popup-content {
    background: #0e3563;
    margin: 0 0 0 20px;

    padding: 5px 8px 2px 8px;
    box-shadow: none;
    border-radius: 0;
  }

  & .mapboxgl-popup-close-button {
    display: none;
  }

  & a {
    text-decoration: none !important;
    border-bottom: 0 !important;

    &:hover {
      border-bottom: 0 !important;
    }
  }
`;

const PopupContainer = styled.div`
  color: #fff;

  & p {
    margin: 0;

    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;

    text-align: center;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
  }

  & svg {
    margin: 0 0 0 15px;

    width: 14px;
    height: auto;
  }
`;

export const Map = ({
  viewport,
  setViewport,
  activeMarker,
  allMapLocations,
}) => (
  <MapContainer>
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      onViewportChange={nextViewport => setViewport(nextViewport)}
      mapboxApiAccessToken={process.env.GATSBY_MAPBOX}
      mapStyle="mapbox://styles/covers/ckhyryqg4237n19qu0nsriw0c"
      scrollZoom={false}
      maxZoom={15}
      minZoom={4}
    >
      <Navigation />
      {allMapLocations}

      {activeMarker !== null && (
        <Popup
          latitude={activeMarker.data.location.latitude}
          longitude={activeMarker.data.location.longitude}
          anchor="left"
        >
          <PopupContainer>
            <p className="transcript">
              <Link to={activeMarker.url}>
                {activeMarker.data.title.text}
                <LinkIcon fill={`#fff`} />
              </Link>
            </p>
          </PopupContainer>
        </Popup>
      )}
    </ReactMapGL>
  </MapContainer>
);
